import {withAuthenticationRequired} from '@auth0/auth0-react';
import {Box, CircularProgress} from '@mui/material';
import NProgress from 'nprogress';
import {useEffect} from 'react';

export function MiniSuspenseLoader(props: any) {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{width: '100%', height: '100%'}}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress thickness={3} {...props} />
    </Box>
  );
}

function SuspenseLoader() {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <Box
      sx={{width: '100%', height: '100%'}}
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <CircularProgress size={64} disableShrink thickness={3} />
    </Box>
  );
}

export default withAuthenticationRequired(SuspenseLoader, {
  // Show a message while the user waits to be redirected to the login page.
  //onRedirecting: () => <div>Redirecting you to the login page...</div>,
});
