import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import {Box, Hidden, IconButton, Tooltip} from '@mui/material';
import {experimentalStyled} from '@mui/material/styles';
import {useContext} from 'react';
import Logo from 'src/components/Logo';
import {SidebarContext} from 'src/contexts/SidebarContext';
import {customThemeStyles} from 'src/theme/schemes/PureLightTheme';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

const HeaderWrapper = experimentalStyled(Box)(
  ({theme}) => `
        height: ${customThemeStyles.header.height};
        color: ${customThemeStyles.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${customThemeStyles.header.background};
        box-shadow: ${customThemeStyles.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${customThemeStyles.sidebar.width};
            width: auto;
        }
`,
);

function Header() {
  const {sidebarToggle, toggleSidebar} = useContext(SidebarContext);

  return (
    <HeaderWrapper display='flex' alignItems='center'>
      <Box display='flex' alignItems='center'>
        <Hidden lgUp>
          <Logo />
        </Hidden>
        {/* <Hidden mdDown>
          <HeaderMenu />
        </Hidden> */}
      </Box>
      <Box display='flex' alignItems='center'>
        <HeaderButtons />
        <HeaderUserbox />
        <Hidden lgUp>
          <Tooltip arrow title='Menü'>
            <IconButton color='primary' onClick={toggleSidebar} size='large'>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
