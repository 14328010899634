import BarChartIcon from '@mui/icons-material/BarChart';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {ReactNode} from 'react';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const menuItems: MenuItems[] = [
  {
    heading: '',
    items: [
      {
        name: 'Startseite',
        link: '/overview',
        icon: HomeIcon,
      },
    ],
  },
  {
    heading: 'Auswertungen',
    items: [
      {
        name: 'Fangbücher',
        link: '/dashboards/catchManager',
        icon: MenuBookIcon,
      },
    ],
  },
  {
    heading: 'Statistiken',
    items: [
      {
        name: 'Fangbuchauswertung',
        link: '/statistics/catchBooks',
        icon: BarChartIcon,
      },
    ],
  },
  // {
  //   heading: 'Management',
  //   items: [
  //     {
  //       name: 'Transactions',
  //       icon: BarChartIcon,
  //       link: '/management/transactions',
  //     },
  //     {
  //       name: 'User Profile',
  //       icon: BarChartIcon,
  //       link: '/management/profile',
  //       items: [
  //         {
  //           name: 'Profile Details',
  //           link: '/management/profile/details',
  //         },
  //         {
  //           name: 'User Settings',
  //           link: '/management/profile/settings',
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   heading: 'Components',
  //   items: [
  //     {
  //       name: 'Buttons',
  //       icon: BarChartIcon,
  //       link: '/components/buttons',
  //     },
  //     {
  //       name: 'Modals',
  //       icon: BarChartIcon,
  //       link: '/components/modals',
  //     },
  //     {
  //       name: 'Accordions',
  //       icon: BarChartIcon,
  //       link: '/components/accordions',
  //     },
  //     {
  //       name: 'Tabs',
  //       icon: BarChartIcon,
  //       link: '/components/tabs',
  //     },
  //     {
  //       name: 'Badges',
  //       icon: BarChartIcon,
  //       link: '/components/badges',
  //     },
  //     {
  //       name: 'Tooltips',
  //       icon: BarChartIcon,
  //       link: '/components/tooltips',
  //     },
  //     {
  //       name: 'Avatars',
  //       icon: BarChartIcon,
  //       link: '/components/avatars',
  //     },
  //     {
  //       name: 'Cards',
  //       icon: BarChartIcon,
  //       link: '/components/cards',
  //     },
  //     {
  //       name: 'Forms',
  //       icon: BarChartIcon,
  //       link: '/components/forms',
  //     },
  //   ],
  // },
  // {
  //   heading: 'Extra Pages',
  //   items: [
  //     {
  //       name: 'Status',
  //       icon: BarChartIcon,
  //       link: '/status',
  //       items: [
  //         {
  //           name: 'Error 404',
  //           link: '/status/404',
  //         },
  //         {
  //           name: 'Error 500',
  //           link: '/status/500',
  //         },
  //         {
  //           name: 'Maintenance',
  //           link: '/status/maintenance',
  //         },
  //         {
  //           name: 'Coming Soon',
  //           link: '/status/coming-soon',
  //         },
  //       ],
  //     },
  //   ],
  // },
];

export default menuItems;
