import {Box, Hidden, Tooltip} from '@mui/material';
import {experimentalStyled} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {customThemeStyles} from 'src/theme/schemes/PureLightTheme';
import LogoUrl from '../../resources/images/logos/tokyo-logo.png';

const LogoWrapper = experimentalStyled(Link)(
  ({theme}) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`,
);

const LogoSignWrapper = experimentalStyled(Box)(
  () => `
        width: 52px;
        height: 38px;
        margin-top: 4px;
        transform: scale(.8);
`,
);

const LogoTextWrapper = experimentalStyled(Box)(
  ({theme}) => `
        padding-left: ${theme.spacing(1)};
`,
);

const VersionBadge = experimentalStyled(Box)(
  ({theme}) => `
        background: ${theme.palette.success.main};
        color: ${theme.palette.success.contrastText};
        padding: ${theme.spacing(0.4, 1)};
        border-radius: ${customThemeStyles.general.borderRadiusSm};
        text-align: center;
        display: inline-block;
        line-height: 1;
        font-size: ${theme.typography.pxToRem(11)};
`,
);

const LogoText = experimentalStyled(Box)(
  ({theme}) => `
        font-size: ${theme.typography.pxToRem(15)};
        font-weight: ${theme.typography.fontWeightBold};
`,
);

function Logo() {
  return (
    <LogoWrapper to='/overview'>
      <LogoSignWrapper>
        <img style={{width: '100%', height: '100%'}} src={LogoUrl} alt='logo' />
      </LogoSignWrapper>
      <Hidden mdDown>
        <LogoTextWrapper>
          <Tooltip title='Version 1.0' arrow placement='right'>
            <VersionBadge>1.0</VersionBadge>
          </Tooltip>
          <LogoText>Gewässermanager</LogoText>
        </LogoTextWrapper>
      </Hidden>
    </LogoWrapper>
  );
}

export default Logo;
