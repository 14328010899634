import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import FindInPageTwoToneIcon from '@mui/icons-material/FindInPageTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Hidden,
  IconButton,
  InputAdornment,
  lighten,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  Slide,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import {experimentalStyled} from '@mui/material/styles';
import {TransitionProps} from '@mui/material/transitions';
import {ChangeEvent, forwardRef, ReactElement, Ref, useState} from 'react';
import {customThemeColors} from 'src/theme/schemes/PureLightTheme';

const Transition = forwardRef(function Transition(
  props: TransitionProps & {children?: ReactElement<any, any>},
  ref: Ref<unknown>,
) {
  return <Slide direction='down' ref={ref} children={props.children} {...props} />;
});

const DialogWrapper = experimentalStyled(Dialog)(
  () => `
    .MuiDialog-container {
        height: auto;
    }

    .MuiDialog-paperScrollPaper {
        max-height: calc(100vh - 64px)
    }
`,
);

const SearchInputWrapper = experimentalStyled(TextField)(
  ({theme}) => `
    background: ${customThemeColors.alpha.white[100]};

    .MuiInputBase-input {
        font-size: ${theme.typography.pxToRem(17)};
    }
`,
);

const DialogTitleWrapper = experimentalStyled(DialogTitle)(
  ({theme}) => `
    background: ${customThemeColors.alpha.black[5]};
    padding: ${theme.spacing(3)}
`,
);

function HeaderSearch() {
  const [openSearchResults, setOpenSearchResults] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(event.target.value);

    if (event.target.value) {
      if (!openSearchResults) {
        setOpenSearchResults(true);
      }
    } else {
      setOpenSearchResults(false);
    }
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip arrow title='Suche'>
        <IconButton color='primary' onClick={handleClickOpen} size='large'>
          <SearchTwoToneIcon />
        </IconButton>
      </Tooltip>

      <DialogWrapper
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth='md'
        fullWidth
        scroll='paper'
        onClose={handleClose}
      >
        <DialogTitleWrapper>
          <SearchInputWrapper
            value={searchValue}
            autoFocus={true}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchTwoToneIcon />
                </InputAdornment>
              ),
            }}
            placeholder='Suchbegriffe hier eingeben...'
            fullWidth
            label='Suche'
          />
        </DialogTitleWrapper>
        <Divider />

        {openSearchResults && (
          <DialogContent>
            <Box sx={{pt: 0, pb: 1}} display='flex' justifyContent='space-between'>
              <Typography variant='body2' component='span'>
                Search results for{' '}
                <Typography sx={{fontWeight: 'bold'}} variant='body1' component='span'>
                  {searchValue}
                </Typography>
              </Typography>
              <Link href='#' variant='body2' underline='hover'>
                Advanced search
              </Link>
            </Box>
            <Divider sx={{my: 1}} />
            <List disablePadding>
              <ListItem button>
                <Hidden mdDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) => theme.palette.secondary.main,
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex='1'>
                  <Box display='flex' justifyContent='space-between'>
                    <Link href='#' underline='hover' sx={{fontWeight: 'bold'}} variant='body2'>
                      Dashboard for Healthcare Platform
                    </Link>
                  </Box>
                  <Typography
                    component='span'
                    variant='body2'
                    sx={{
                      color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                    }}
                  >
                    This page contains all the necessary information for managing all hospital
                    staff.
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
              <Divider sx={{my: 1}} component='li' />
              <ListItem button>
                <Hidden mdDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) => theme.palette.secondary.main,
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex='1'>
                  <Box display='flex' justifyContent='space-between'>
                    <Link href='#' underline='hover' sx={{fontWeight: 'bold'}} variant='body2'>
                      Example Projects Application
                    </Link>
                  </Box>
                  <Typography
                    component='span'
                    variant='body2'
                    sx={{
                      color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                    }}
                  >
                    This is yet another search result pointing to a app page.
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
              <Divider sx={{my: 1}} component='li' />
              <ListItem button>
                <Hidden mdDown>
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        background: (theme: Theme) => theme.palette.secondary.main,
                      }}
                    >
                      <FindInPageTwoToneIcon />
                    </Avatar>
                  </ListItemAvatar>
                </Hidden>
                <Box flex='1'>
                  <Box display='flex' justifyContent='space-between'>
                    <Link href='#' underline='hover' sx={{fontWeight: 'bold'}} variant='body2'>
                      Search Results Page
                    </Link>
                  </Box>
                  <Typography
                    component='span'
                    variant='body2'
                    sx={{
                      color: (theme: Theme) => lighten(theme.palette.secondary.main, 0.5),
                    }}
                  >
                    Choose if you would like to show or not this typography section here...
                  </Typography>
                </Box>
                <ChevronRightTwoToneIcon />
              </ListItem>
            </List>
            <Divider sx={{mt: 1, mb: 2}} />
            <Box sx={{textAlign: 'center'}}>
              <Button color='primary'>View all search results</Button>
            </Box>
          </DialogContent>
        )}
      </DialogWrapper>
    </>
  );
}

export default HeaderSearch;
