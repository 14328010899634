import {StyledEngineProvider, Theme, ThemeProvider} from '@mui/material';
import {StylesProvider} from '@mui/styles';
import React, {useState} from 'react';
import {themeCreator} from './base';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

export const ThemeContext = React.createContext((themeName: string): void => {
  console.log('themeName: ', themeName);
});

const ThemeProviderWrapper: React.FC = props => {
  const curThemeName = localStorage.getItem('appTheme') || 'PureLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StylesProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
        </StyledEngineProvider>
      </ThemeContext.Provider>
    </StylesProvider>
  );
};

export default ThemeProviderWrapper;
