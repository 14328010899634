import {
  alpha,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import {experimentalStyled} from '@mui/material/styles';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import {useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';

const NotificationsBadge = experimentalStyled(Badge)(
  ({theme}) => `

    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px;
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`,
);

function HeaderNotifications() {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return <>
    <Tooltip arrow title='Benachrichtigungen'>
      <IconButton color='primary' ref={ref} onClick={handleOpen} size="large">
        <NotificationsBadge
          badgeContent={0}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <NotificationsActiveTwoToneIcon />
        </NotificationsBadge>
      </IconButton>
    </Tooltip>
    <Popover
      anchorEl={ref.current}
      onClose={handleClose}
      open={isOpen}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <Box sx={{p: 2}} display='flex' alignItems='center' justifyContent='space-between'>
        <Typography variant='h5'>Benachrichtigungen</Typography>
      </Box>
      <Divider />
      <List sx={{p: 0}}>
        <ListItem sx={{p: 2, minWidth: 350, display: {xs: 'block', sm: 'flex'}}}>
          <Box flex='1'>
            <Box display='flex' justifyContent='space-between'>
              <Typography sx={{fontWeight: 'bold'}}>Wilkommen</Typography>
              <Typography variant='caption' sx={{textTransform: 'none'}}>
                gerade eben
              </Typography>
            </Box>
            <Typography component='span' variant='body2' color='text.secondary'>
              {' '}
              Schön dass du da bist ❤️️ <br />
              <br />
              Werte <RouterLink to='/dashboards/catchManager/add'>hier</RouterLink> dein erstes
              Fangbuch aus. <br />
              <br />
              <strong>Viel Spaß dabei 😎</strong>
            </Typography>
          </Box>
        </ListItem>
      </List>
    </Popover>
  </>;
}

export default HeaderNotifications;
