import {useAuth0} from '@auth0/auth0-react';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import {Avatar, Box, Button, Divider, Hidden, lighten, Popover, Typography} from '@mui/material';
import {experimentalStyled} from '@mui/material/styles';
import {useRef, useState} from 'react';
import {customThemeColors} from 'src/theme/schemes/PureLightTheme';

const UserBoxButton = experimentalStyled(Button)(
  ({theme}) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`,
);

const MenuUserBox = experimentalStyled(Box)(
  ({theme}) => `
        background: ${customThemeColors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`,
);

const UserBoxText = experimentalStyled(Box)(
  ({theme}) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`,
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({theme}) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`,
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({theme}) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`,
);

function HeaderUserbox() {
  const auth = useAuth0();
  const {user: authUser} = auth;

  const user = {
    name: '',
    avatar: '',
    jobtitle: 'Vorstandsmitglied',
  };

  if (authUser) {
    const {nickname, picture} = authUser;
    user.name = nickname;
    user.avatar = picture;
  }

  const ref = useRef<any>(null);
  const {isAuthenticated, logout, loginWithRedirect} = useAuth0();
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color='secondary' ref={ref} onClick={handleOpen}>
        <Avatar variant='rounded' alt={user.name} src={user.avatar} />
        <Hidden lgDown>
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden mdDown>
          <ExpandMoreTwoToneIcon sx={{ml: 1}} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuUserBox sx={{minWidth: 210}} display='flex'>
          <Avatar variant='rounded' alt={user.name} src={user.avatar} />
          <UserBoxText>
            <UserBoxLabel variant='body1'>{user.name}</UserBoxLabel>
            <UserBoxDescription variant='body2'>{user.jobtitle}</UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{mb: 0}} />
        {/* <List sx={{p: 1}} component='nav'>
        <ListItem button to='/management/profile/details' component={NavLink}>
          <AccountBoxTwoToneIcon fontSize='small' />
          <ListItemText primary='My Profile' />
        </ListItem>
        <ListItem button to='/dashboards/messenger' component={NavLink}>
          <InboxTwoToneIcon fontSize='small' />
          <ListItemText primary='Messenger' />
        </ListItem>
        <ListItem button to='/management/profile/settings' component={NavLink}>
          <AccountTreeTwoToneIcon fontSize='small' />
          <ListItemText primary='Account Settings' />
        </ListItem>
      </List> */}
        <Divider />
        <Box sx={{m: 1}}>
          {isAuthenticated ? (
            <Button
              color='primary'
              fullWidth
              onClick={() => logout({returnTo: window.location.origin})}
            >
              <LockOpenTwoToneIcon sx={{mr: 1}} />
              Sign out
            </Button>
          ) : (
            <Button color='primary' fullWidth onClick={loginWithRedirect}>
              <LockOpenTwoToneIcon sx={{mr: 1}} />
              Sign in
            </Button>
          )}
        </Box>
      </Popover>
    </>
  );
}

export default HeaderUserbox;
