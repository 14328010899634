import {lazy, Suspense} from 'react';
import {RouteObject} from 'react-router';
import {Navigate} from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import SidebarLayout from 'src/layouts/SidebarLayout';

function Loader(Component) {
  return props => {
    return (
      <Suspense fallback={<SuspenseLoader />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Dashboards

const CatchManager = Loader(lazy(() => import('src/content/dashboards/CatchManager')));
const CatchManagerAdd = Loader(
  lazy(() => import('src/content/dashboards/CatchManager/RecentOrdersForm')),
);
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));

// Stats

const CatchBookStatistics = Loader(lazy(() => import('src/content/dashboards/statistics/Charts')));

// Applications

const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
const Transactions = Loader(lazy(() => import('src/content/dashboards/CatchManager')));
const UserProfile = Loader(lazy(() => import('src/content/applications/Users/profile')));
const UserSettings = Loader(lazy(() => import('src/content/applications/Users/settings')));

// Components

const Buttons = Loader(lazy(() => import('src/content/pages/Components/Buttons')));
const Modals = Loader(lazy(() => import('src/content/pages/Components/Modals')));
const Accordions = Loader(lazy(() => import('src/content/pages/Components/Accordions')));
const Tabs = Loader(lazy(() => import('src/content/pages/Components/Tabs')));
const Badges = Loader(lazy(() => import('src/content/pages/Components/Badges')));
const Tooltips = Loader(lazy(() => import('src/content/pages/Components/Tooltips')));
const Avatars = Loader(lazy(() => import('src/content/pages/Components/Avatars')));
const Cards = Loader(lazy(() => import('src/content/pages/Components/Cards')));
const Forms = Loader(lazy(() => import('src/content/pages/Components/Forms')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const routes: RouteObject[] = [
  {
    path: '/',
    element: <SidebarLayout />,
    children: [
      {
        path: '/',
        element: <Overview />,
      },
      {
        path: '/overview',
        element: <Navigate to='/' replace />,
      },
      {
        path: '/status',
        children: [
          {
            path: '/status',
            element: <Navigate to='404' replace />,
          },
          {
            path: '/status/404',
            element: <Status404 />,
          },
          {
            path: '/status/500',
            element: <Status500 />,
          },
          {
            path: '/status/maintenance',
            element: <StatusMaintenance />,
          },
          {
            path: '/status/coming-soon',
            element: <StatusComingSoon />,
          },
          {
            path: '*',
            element: <Status404 />,
          },
        ],
      },
    ],
  },
  {
    path: '/dashboards',
    element: <SidebarLayout />,
    children: [
      {
        path: '/dashboards/',
        element: <Navigate to='/dashboards/catchManager' replace />,
      },
      {
        path: '/dashboards/catchManager',
        element: <CatchManager />,
      },
      {
        path: '/dashboards/catchManager/add',
        element: <CatchManagerAdd />,
      },
      {
        path: '/dashboards/catchManager/add/:memberId',
        element: <CatchManagerAdd />,
      },
      {
        path: '/dashboards/crypto',
        element: <Crypto />,
      },
      {
        path: '/dashboards/messenger',
        element: <Messenger />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },

  //Charts
  {
    path: '/statistics',
    element: <SidebarLayout />,
    children: [
      {
        path: '/statistics/',
        element: <Navigate to='/dashboards/statistics/catchBooks' replace />,
      },
      {
        path: '/statistics/catchBooks',
        element: <CatchBookStatistics />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: '/statistics/management',
    element: <SidebarLayout />,
    children: [
      {
        path: '/statistics/management/',
        element: <Navigate to='/management/transactions' replace />,
      },
      {
        path: '/statistics/management/transactions',
        element: <Transactions />,
      },
      {
        path: '/statistics/management/profile',
        children: [
          {
            path: '/statistics/management/profile/',
            element: <Navigate to='details' replace />,
          },
          {
            path: '/statistics/management/profile/details',
            element: <UserProfile />,
          },
          {
            path: '/statistics/management/profile/settings',
            element: <UserSettings />,
          },
          {
            path: '*',
            element: <Status404 />,
          },
        ],
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: '/components',
    element: <SidebarLayout />,
    children: [
      {
        path: '/components/',
        element: <Navigate to='/components/buttons' replace />,
      },
      {
        path: '/components/buttons',
        element: <Buttons />,
      },
      {
        path: '/components/modals',
        element: <Modals />,
      },
      {
        path: '/components/accordions',
        element: <Accordions />,
      },
      {
        path: '/components/tabs',
        element: <Tabs />,
      },
      {
        path: '/components/badges',
        element: <Badges />,
      },
      {
        path: '/components/tooltips',
        element: <Tooltips />,
      },
      {
        path: '/components/avatars',
        element: <Avatars />,
      },
      {
        path: '/components/cards',
        element: <Cards />,
      },
      {
        path: '/components/forms',
        element: <Forms />,
      },
      {
        path: '*',
        element: <Status404 />,
      },
    ],
  },
  {
    path: '*',
    element: <Status404 />,
  },
];

export default routes;
