import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {CssBaseline} from '@mui/material';
import {StyledEngineProvider} from '@mui/material/styles';
import {useRoutes} from 'react-router-dom';
import routes from './router';
import ThemeProvider from './theme/ThemeProvider';

const App = () => {
  const content = useRoutes(routes);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          {content}
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
export default App;
