import {Auth0Provider} from '@auth0/auth0-react';
import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import {BrowserRouter} from 'react-router-dom';
import 'src/utils/chart';
import App from './App';
import {SidebarProvider} from './contexts/SidebarContext';
import * as serviceWorker from './serviceWorker';

const domain = process.env.REACT_APP_AUTH0_DOMAIN || 'no-auth-domain';
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || 'no-client-id';
const audience = process.env.REACT_APP_AUTH0_AUDIENCE || 'no-audience';

ReactDOM.render(
  <Auth0Provider
    domain={domain}
    clientId={clientId}
    audience={audience}
    redirectUri={window.location.origin}
  >
    <HelmetProvider>
      <SidebarProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </SidebarProvider>
    </HelmetProvider>
  </Auth0Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
